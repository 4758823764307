import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/views/login/components/LoginForm.vue")
      },
      {
        path: "register",
        name: "register",
        component: () => import("@/views/login/components/RegisterForm.vue")
      }
    ]
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/layout/main/index.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/home/index.vue")
      },
      {
        path: "check",
        name: "check",
        component: () => import("@/views/home/components/teacher_components/CheckCourse.vue")
      },
      {
        path: "detailWork",
        name: "detailHomeWork",
        component: () => import("@/components/homework/DetailHomeWork.vue")
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
