<template>
  <div id="app">
    <router-view v-slot="{ Component, route }">
      <!--   先暂时空着   -->
      <keep-alive
        :include="[]"
        :exclude="['login']"
      >
        <component
          :is="Component"
          :key="$route.fullPath"
        />
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const userStore = JSON.parse(localStorage.getItem("userStore") || "{}");
const isAutoLogin = ref(userStore.autoLogin === "1");
autoLogin();

async function autoLogin () {
  if (!isAutoLogin.value) return;

  const router = useRouter();
  await router.push("/home");
}

</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  position: relative;
}

.could-hover {
  cursor: pointer;
}
</style>
